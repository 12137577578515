import Haytham from "../assets/client1.png";
import Abhibagel from "../assets/client2.png";
import soeng from "../assets/client3.png";
import jay from "../assets/client4.png";
const reviews = [
    {
      id: 1,
      name: 'Haytham',
      job: 'Manager',
      image: Haytham,
      text:
        "I am so glad to work with him as he a potential one they can do his work prefectly, and for another future chance, will not to hesitate to contant him",
    },
    {
      id: 2,
      name: 'Abhibagel',
      job: 'Enterpreneur',
      image: Abhibagel,
      text:
      "Working with Mr.Ahsan was one of the best experiences I had when working with help from the internet. The service was excellent and top notch and Mr.Ahsan and his team were always ready to work and to meet our requirements. They always explained what they did in the best manner possible showing how well experienced they were. 10/10 for quality."
    },
    {
      id: 3,
      name: 'Soeng Jim Jim',
      job: 'Artist',
      image:soeng,
      text:
      "The delivery were on time and got the code I wanted. Even after the model was delivered, he kept in touch with me until I was satisfied with the result. It was a great experience working with this seller and I will definitely work with him again if there is an opportunity. "
    },
    {
      id: 4,
      name: 'Jay',
      job: 'Consultant',
      image:jay,
      text:
        "Very skilled and very helpful with the project I definitely recommend him if you have any deep-learning projects.  "
    },
  ];
  
  export default reviews;