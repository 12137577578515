import React, { useState } from 'react';
import './DesktopApplications.css';
import agricultureTissueImage from "../../assets/agricultureTissueImage.png";
import tunnelFarmingPlasticRollImage from "../../assets/tunnelFarmingPlasticRollImage.png";
import constructionRollImage from "../../assets/onstructionRollImage.png";
import packagingBagsImage from "../../assets/packagingBagsImage.png";
import recyclingPolyethyleneBagsImage from "../../assets/recyclingPolyethyleneBagsImage.png";
import importedLLDPEImage from "../../assets/importedLLDPEImage.png";
import ldpeImage from "../../assets/ldpeImage.png";
import blackMulchingSheetsImage from "../../assets/blackMulchingSheetsImage.png";


const Insutrial = () => {
  const cards = [
    { id: 1, text: 'Agriculture Tissue', image: agricultureTissueImage, backgroundColor: '#819171' },
    { id: 2, text: 'Tunnel Farming Plastic Roll', image: tunnelFarmingPlasticRollImage, backgroundColor: '#045F29' },
    { id: 3, text: 'Construction Roll', image: constructionRollImage, backgroundColor: '#344E41' },
    { id: 4, text: 'Packaging Bags', image: packagingBagsImage, backgroundColor: '#0F5132' },
    { id: 5, text: 'Recycling Polyethylene Bags Making', image: recyclingPolyethyleneBagsImage, backgroundColor: '#2D2271' },
    { id: 6, text: 'Imported LLDPE from UAE, Saudi Arabia, USA', image: importedLLDPEImage, backgroundColor: '#123456' },
    { id: 7, text: 'LDPE', image: ldpeImage, backgroundColor: '#654321' },
    { id: 8, text: 'Black Mulching Sheets for Agriculture Use', image: blackMulchingSheetsImage, backgroundColor: '#83478E' }
  ];
  

  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = cards.length;
  const cardsToShow = 6;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
  };

  const getVisibleCards = () => {
    const endIndex = currentIndex + cardsToShow;
    if (endIndex <= totalSlides) {
      return cards.slice(currentIndex, endIndex);
    } else {
      return [...cards.slice(currentIndex, totalSlides), ...cards.slice(0, endIndex - totalSlides)];
    }
  };

  return (
    <div className="carousel-container">
      <button className="carousel-arrow left-arrow" onClick={prevSlide}>&#8249;</button>
      <div className="carousel-content">
        {getVisibleCards().map((card) => (
          <div key={card.id} className="carousel-card" style={{ backgroundColor: card.backgroundColor }}>
            <a className="card-link" href={`/contactus?project=${encodeURIComponent(card.text)}`}>
              <img src={card.image} alt={card.text} />
              <p>{card.text}</p>
            </a>
          </div>
        ))}
      </div>
      <button className="carousel-arrow right-arrow" onClick={nextSlide}>&#8250;</button>
      <div className="carousel-pagination">
        {Array.from({ length: totalSlides }).map((_, index) => (
          <span
            key={index}
            className={`pagination-dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Insutrial;
